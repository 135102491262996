import '../assets/styles/main.css';

import Header from './Header';
import Landing from '../view/Ladning';
import About from '../view/About';
import Skills from '../view/Skills';
import Education from '../view/Education';
import ThemeSelector from '../utils/ThemeSelector';
import Portfolio from '../view/Portfolio';
import Contact from '../view/Contact';
import Experience from '../view/Experience';

function Layout() {
  return (
    <div id="cv">
      <ThemeSelector />
      <Header />
      <Landing />
      <About />
      <Portfolio />
      <Skills />
      <Education />
      <Experience />
      <Contact />
    </div>
  );
}

export default Layout;
