function Landing() {
  return (
    <div className="landing">
      <div className="anchor" id="landing"></div>
      <div className="landing__box">
        <p className="landing__box__namemobile">
          SEBASTIAN
          <b> GUSTAVSSON</b>
        </p>
        <div className="landing__box__line1"></div>
        <p className="landing__box__title">
          <span>F</span>
          <span>U</span>
          <span>L</span>
          <span>L</span>
          <span>-</span>
          <span>S</span>
          <span>T</span>
          <span>A</span>
          <span>C</span>
          <span>K</span>
          <span> </span>
          <span>D</span>
          <span>E</span>
          <span>V</span>
          <span>E</span>
          <span>L</span>
          <span>O</span>
          <span>P</span>
          <span>E</span>
          <span>R</span>
        </p>
        <div className="landing__box__line2"></div>
        <p className="landing__box__quote">I never stop learning, nor exploring</p>
        {/* <SchoolProgress /> */}
      </div>
    </div>
  );
}

export default Landing;
