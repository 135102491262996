import { Route, Routes } from 'react-router-dom';

import { default as Cv } from './layouts/Layout';

import './resets.css';

function App() {
  // console.log('App loaded');

  return (
    <Routes>
      <Route path="/" element={<Cv />} />
    </Routes>
  );
}

export default App;
