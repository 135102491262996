function EducationBox({ school, year, course }) {
  return (
    <div className="comp_EducationBox">
      <h3 className="comp_EducationBox__school">{school}</h3>
      <p className="comp_EducationBox__course">{course}</p>
      <p className="comp_EducationBox__year">{year}</p>
    </div>
  );
}

export default EducationBox;
