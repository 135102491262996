import icon from '../assets/svg/Manypixels_Career_Outline.svg';
import Projects from './Portfolio.Projects';
// import ProjectCard from '../components/ProjectsCard';

function Portfolio() {
  return (
    <div className="portfolio layout-child">
      <div className="anchor" id="portfolio"></div>
      <div className="layout-child__left">
        <h2>Portfolio</h2>
        <div className="layout-child__left__line"></div>
        <div className="layout-child__left__image">
          <img src={icon} alt="Portfolio" />
        </div>
      </div>
      <div className="layout-child__right">
        <div className="portfolio__container">
          <Projects />
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
