import { FaLinkedin } from 'react-icons/fa';

import icon from '../assets/svg/Manypixels_Sent_messages_Outline.svg';

function Contact() {
  return (
    <div className="contact layout-child">
      <div className="anchor" id="contact"></div>
      <div className="layout-child__left">
        <h2>Contact me </h2>
        <div className="layout-child__left__line"></div>
        <div className="layout-child__left__image">
          <img src={icon} alt="Contact" />
        </div>
      </div>
      <div className="layout-child__right contact__right">
        <p>
          You can either <a href="mailto:sebastian@gsson.dev">send me an email</a>
        </p>
        <br />
        <p>
          or contact me on{' '}
          <a
            href="https://www.linkedin.com/in/sebastian-gustavsson-066985125/"
            target="_blank"
            rel="noreferrer noopener"
          >
            LinkedIn.
          </a>
        </p>
        <a
          aria-label="LinkedIn"
          href="https://www.linkedin.com/in/sebastian-gustavsson-066985125/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <FaLinkedin />
        </a>
      </div>
    </div>
  );
}

export default Contact;
