/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect } from 'react';
import { VscColorMode } from 'react-icons/vsc';

function ToggleThemeButton() {
  const svgbutton = document.querySelector('.util_themeselector svg');
  const options = document.querySelector('.util_themeselector__options');

  svgbutton.addEventListener('click', () => {
    svgbutton.style.display = 'none';
    options.style.display = 'flex';
    window.addEventListener('scroll', () => {
      if (window.innerHeight - 80 < window.scrollY) {
        svgbutton.style.display = 'block';
        options.style.display = 'none';
      }
    });
  });
}

function SetTheme1() {
  const root = document.documentElement;
  // Light-blue
  root.style.setProperty('--main-color', 'rgb(28, 160, 212)');
  root.style.setProperty('--second-color', '#fff');
  root.style.setProperty('--text-color', 'rgb(22, 22, 22)');
  root.style.setProperty('--progbar-end', 'rgb(183, 228, 248)');
  root.style.setProperty('--progbar-title', 'rgb(46, 132, 167)');
}

function SetTheme2() {
  const root = document.documentElement;
  // Dark-red
  root.style.setProperty('--main-color', 'rgb(169 38 38)');
  root.style.setProperty('--second-color', 'rgb(40 40 40)');
  root.style.setProperty('--text-color', 'rgb(215 215 215)');
  root.style.setProperty('--progbar-end', 'rgb(245, 132, 132)');
  root.style.setProperty('--progbar-title', 'rgb(201 201 201)');
}

function SetTheme3() {
  const root = document.documentElement;
  // Dark-teal
  root.style.setProperty('--main-color', 'rgb(0,128,128)');
  root.style.setProperty('--second-color', 'rgb(40 40 40)');
  root.style.setProperty('--text-color', 'rgb(240 240 240)');
  root.style.setProperty('--progbar-end', 'rgb(71, 197, 197)');
  root.style.setProperty('--progbar-title', 'rgb(230, 230, 230)');
}

function CheckForDarkMode() {
  SetTheme3();

  // if (window.matchMedia) {
  //   // Check if the dark-mode Media-Query matches
  //   if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
  //     // console.log("Dark")
  //     SetTheme3();
  //   } else {
  //     // console.log("Light")
  //     SetTheme1();
  //   }
  // } else {
  //   // Default (when Media-Queries are not supported)
  //   SetTheme1();
  // }
}

function ThemeSelector() {
  useEffect(() => {
    // console.log('Themeselector loaded');
    CheckForDarkMode();
    ToggleThemeButton();
  });

  return (
    <div className="util_themeselector" title="Theme">
      <VscColorMode />
      <div className="util_themeselector__options">
        <div className="util_themeselector__options__opt1" onClick={SetTheme1}></div>
        <div className="util_themeselector__options__opt2" onClick={SetTheme2}></div>
        <div className="util_themeselector__options__opt3" onClick={SetTheme3}></div>
      </div>
    </div>
  );
}

export default ThemeSelector;
