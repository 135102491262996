import { useEffect } from 'react';

function Header() {
  useEffect(() => {
    // console.log("Header loaded");

    window.addEventListener('scroll', () => {
      if (window.innerHeight - 80 < window.scrollY) {
        document.querySelector('#header').style.backgroundColor = 'var(--main-color)';
        document.querySelector('.util_themeselector').style.display = 'none';
        document
          .querySelectorAll('#header li')
          .forEach((el) => el.classList.add('li-mobile-white'));
      } else if (window.innerHeight > window.scrollY) {
        document.querySelector('#header').style.backgroundColor = '';
        document.querySelector('.util_themeselector').style.display = 'block';
        document
          .querySelectorAll('#header li')
          .forEach((el) => el.classList.remove('li-mobile-white'));
      }
    });
  });

  return (
    <div id="header">
      <a aria-label="Home" href="#landing">
        <p>
          <span>S</span>
          <span>E</span>
          <span>B</span>
          <span>A</span>
          <span>S</span>
          <span>T</span>
          <span>I</span>
          <span>A</span>
          <span>N</span>
          <span> </span>
          <b>
            <span>G</span>
            <span>U</span>
            <span>S</span>
            <span>T</span>
            <span>A</span>
            <span>V</span>
            <span>S</span>
            <span>S</span>
            <span>O</span>
            <span>N</span>
          </b>
        </p>
      </a>
      <ul>
        <li>
          <a href="#about">ABOUT</a>
        </li>
        <li>
          <a href="#portfolio">PORTFOLIO</a>
        </li>
        <li>
          <a href="#skills">SKILLS</a>
        </li>
        <li>
          <a href="#education">EDUCATION</a>
        </li>
        <li>
          <a href="#experience">EXPERIENCE</a>
        </li>
        <li>
          <a href="#contact">CONTACT</a>
        </li>
      </ul>
    </div>
  );
}

export default Header;
