import icon from "../assets/svg/Manypixels_School_Outline.svg";
import EducationBox from "../components/EducationBox";

function Education() {
  return (
    <div className="education layout-child">
      <div className="anchor" id="education"></div>
      <div className="layout-child__left">
        <h2>EDUCATION</h2>
        <div className="layout-child__left__line"></div>
        <div className="layout-child__left__image">
          <img src={icon} alt="Book" />
        </div>
      </div>
      <div className="layout-child__right">
        <EducationBox
          school="Jönköping University"
          year="Aug 2022 - Jun 2024"
          course="C# .NET Web Developer"
        />
        <EducationBox
          school="Bäckadalsgymnasiet"
          year="Aug 2007 - Jun 2010"
          course="EL - Dator- och kommunikationsteknik"
        />
      </div>
    </div>
  );
}

export default Education;
