function Tool({ name, icon }) {
  return (
    <div className="comp_tool">
      {icon}
      <span>{name}</span>
    </div>
  );
}

export default Tool;
