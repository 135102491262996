/* eslint-disable max-len */
import image from '../assets/images/me.webp';

function About() {
  return (
    <div className="about layout-child">
      <div className="anchor" id="about"></div>
      <div className="layout-child__left">
        <h2>ABOUT ME</h2>
        <div className="layout-child__left__line"></div>
        <div className="layout-child__left__image">
          <img src={image} alt="Me" />
        </div>
      </div>
      <div className="layout-child__right about__right">
        <h3>Sebastian Gustavsson</h3>
        <p>
          <br />
          Hi, I'm Sebastian. I'm 32 years old and I live in Taberg, Sweden.
          <br />
          I live with my wife and our two wonderful daughters. 👨‍👩‍👧‍👧
          <br />
          <br />
          When I'm not coding I like to run, hike and read. My reading material is usually fantasy,
          and mainly tends to be audiobooks🎧 even though I prefer physical books.
          <div className="about__divider"></div>
          I began learning to code in 2019 after many years of being curious about it.
          <br />
          I was fortunate enough to be able to apply it to my current job at the time, and that
          transitioned into a position as a product owner.
          <br />
          <br />I then felt that coding was something I would like to get even better at and make
          into a career, so I applied for the 2 year education "Webdeveloper .NET" at Jönöping
          University, witch I am now attending. 🎓
          <div className="about__divider"></div>
          My main strengths are that I'm self driven, team player, quick learner, optimistic and not
          afraid of taking the lead if needed.
          <br />
          <br />
          In my previous positions I've always been afforded time to improve how we work and measure
          ourselves. This has given me a good foundation of analysing workflows, improving tasks,
          and finding new solutions out of both practical and statistical standpoints. 📈
          <br />
          <br />
          Between 2021-2022 I worked as a product owner so I'm very familiar working agile with
          Scrum/Kanban.
        </p>
      </div>
    </div>
  );
}

export default About;
